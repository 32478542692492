import React, { useState } from 'react';

// Icon
import { Iconsax } from "@components-common";

// redux
import { useSelector } from "react-redux";

// context
import { useScreenSize } from '@context/ScreenSizeContext';

// components
import { SendIcon } from "../FreeText";

function InputFreeText(props) {
  // props
  const { handleSubmitChoices, handleSubmitFreeText, isLoading, valueOptions, isMessageLoader, disabled } = props;

  // Check Lock Elements
  const isChatPending = useSelector((state) => state.isChatPending || {});
  const isLock = isChatPending?.isLock;

  // state
  const [value, setValue] = useState('');

  // send Api
  const handleSubmit = () => {
    if (value) {
      handleSubmitFreeText(value);
      setValue(''); // Clear the value after successful submit
    } else {
      handleSubmitChoices();
    }
  };

  // handle change
  const handleChange = (e) => {
    setValue(e.target.value);
  };

  // handle key press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Ngăn hành vi mặc định của Enter
      handleSubmit();
    }
  };

  // Use screen size context
  const { isMobile } = useScreenSize();

  return (
    <div className="chatFooter">
      <div className="position-relative w-100">
        <textarea
          name="answer"
          placeholder="Enter your answer or ask me for help here..."
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={valueOptions || value}
          disabled={disabled || isLoading || isLock}
          autoComplete="off"
          rows="3" // Số dòng hiển thị mặc định
          className={`form-control ${isMobile ? "textarea-format-mobile" : ""}`}
          style={{ resize: "none", width: "100%" }} // Ngăn resize và làm cho chiều rộng 100%
        />
        {isMobile && <SendIcon onClick={handleSubmit} />}
      </div>
      {!isMobile &&
        <button
          type="button"
          className="btn-send ml-2"
          onClick={handleSubmit}
          disabled={isLoading || isLock}
        >
          {isMessageLoader ? (
            <span className="mr-2 text-nowrap">
              {isMessageLoader}
              <span className="loading-dots"></span>
            </span>
          ) : (
            <span className="mr-2 text-nowrap">Send</span>
          )}
          {isLoading ? (
            <span className="spinner-border spinner-border-sm ml-2"></span>
          ) : (
            <Iconsax iconName="send" fill="#fff" />
          )}
        </button>
      }
    </div>
  );
};

export default InputFreeText;