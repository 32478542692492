import {
  combineReducers
} from "redux";
import {
  routerReducer
} from "react-router-redux";
import {
  authUser,
  resetPassword,
  authCodeList,
  createAuthcode
} from "./auth.reducer";
import {
  cases,
  actionsWidthCaseId,
  dataFeedbacks,
  valueChatSubmit,
  checkMissingTask,
  caseTaskCurrent,
  InterfaceTemplateData,
  statusTaskActiveTabMedicalRecord,
  VirtualPreceptorStatus
} from "./case.reducer";
import {
  portfolio
} from "./portfolio.reducer";
import {
  series
} from "./series.reducer";
import {
  question,
  allQuestionForelement,
  chatRecordForQuestion
} from "./question.reducer";
import {
  postQuestion
} from "./postQuestion.reducer";
import {
  pnorder,
  pnorderUpdate,
  pnorderCheck,
  loadPnOrders,
} from "./pnorder.reducer";
import {
  instructor,
  accountInstitution,
  loadInstitutionAllCases,
  seriesLoadById,
} from "./instructor.reducer";
import {
  teachCase,
  createCase,
  deleteCase,
  caseLearnersList,
  loadQuestion,
  loadTemplates,
  allTemplate,
  loadCandidatesForLearner,
  loadAllQuestion,
  loadTechHome,
  listTemplateCollectionById,
  listAnswerbyQuestionId,
  listQuestionFormTemplates,
  statusTaskActive,
  questionViewActive
} from "./teach.reducer";
import {
  getListCaseScript,
  loadCaseScript,
  saveCaseScript,
  getTemplateCaseScript,
  getQuestionBank,
  getQuestionBankOrg,
  shareMediaOrg,
  getListLearner,
  getRAGDocumentByAccount,
  getMcqUsage,
  getDetailedMcqUsage,
  getSlideTemplates,
  getListSlideDesks,
  loadSlideDesk,
  createEngage,
  getAllEngages,
  getReferenceBook,
  shareReference,
  uploadFileAnalysis,
  createNewAnalysis,
  getListAnalysesAvailabel,
  getAnalysesAnswerBatch,
  getAnalysesAnswerBatchStatistics,
  getReferenceMedia,
} from "./teachV2.reducer";
import {
  loadWizard,
  loadNextStepWizard,
  fluxImageGeneration,
} from "./wizard.reducer"
import {
  theme
} from "./theme.reducer";
import {
  advance,
  caseCompleted,
  checkAutoNextTimepoint,
  checkStatusNextTimePoint
} from "./advance.reducer";
import {
  timeline,
  timepoint,
  loadLabs,
  loadAttributesForEffects,
  loadExamFindingById,
  loadChat,
  extractVitalSigns,
  extractLabs
} from "./storyboard.reducer";
import {
  dataPromptTemplate,
} from "./promptTemp.reducer";
import {
  loadStudies,
  extractStudies,
  dataStudiesForElement
} from "./studies.reducer";
import {
  loadActionsTarget
} from "./action.reducer";
import {
  loadCheckpoints,
  itemsByCheckPoints
} from "./checkpoints.reducer";
import orders from "./orders";
import {
  register,
  verifyRegister
} from "./register.reducer";
import {
  loadMedications
} from "./medication.reducer";
import {
  listCohort,
  dataLearnersByCohortId
} from "./cohorts.reducer";
import {
  loadPeriods
} from "./periods.reducer";
import {
  managers,
  loadCandidatesForManagers
} from "./managers.reducer";
import {
  loadFeedbackTemplate,
  ListAvailableFeedbackTemplates,
  typeChatforChatFeedback,
  chatFeedbackReducer,
  ERMModalMobileReducer,
  countChatVoice
} from "./feedback.reducer";
import {
  submitTicket,
  loadTicket
} from "./ticket.reducer";
import {
  listLearnersReport,
  getPerformanceFeedbacks,
  getQuestionRecord,
  dataDomainRecord,
  dataOnboaringReport,
  updateQuestionGradingFields,
  loadStatisticsQuestionReport,
  listChatReport,
  listTopic
} from "./report.reducer";
import {
  loadAllLaunchPlans,
  loadPlanDetails,
  featureList
} from "./plan.reducer";
import {
  loadTimeZoneInstitution,
  loadAccountTimeZone
} from "./timezone.reducer";
import {
  getAssistantByid
} from "./assistant.reducer";
import {
  getAgreements,
  getDetailAgreements,
  getAllStandardChatTopics
} from "./agreements.reducer";
import {
  billingReport
} from "./billing.reducer";
import {
  LoadStripeCreditCard,
  dataAutomaticReNew,
  DataPaymentMethod
} from "./payment.reducer";
import {
  dataVitalSignsByCaseId
} from "./vital.reducer";
import {
  dataExamFindingsByCaseId,
  examFindingsByAtributeId,
  examMobileReducer
} from "./examFindings.reducer";
import {
  dataScrum,
  dataScrumById
} from "./scrum.reducer";
import {
  dataSchema,
  dataQuestionForSchema,
  dataSearchSchema,
  dataTestQuestion
} from "./schema.reducer";
import {
  listAccountSettings,
  getAccountInfo
} from "./profile.reducer";
import {
  dataIntakeOutput,
  listLabsForElement,
  loadChatTopics,
  listStandardChatTopics,
  listMedicationsForElement,
  listActionsForElement,
  loadCaseByIdForElement
} from "./elements.reducer";
import {
  LoadProblems
} from "./problems.reducer";
import {
  getAssociatedSchema
} from "./associatedSchema.reducer";
import {
  ChatRecordFreeText,
  dataLoadRecordInstuctor,
  dataLoadRecordPatient,
  isChatPending
} from "./chat.reducer";
import {
  getTagsForValidation
} from "./validate.reducer";
import {
  listInterfaceTemplates,
  listStandardInterfaceElements
} from "./InterfaceTemplates.reducer";
import {
  listEngage
} from "./engage.reducer";
import {
  getCoin,
  getCoinMcq,
  getCoinCaseScript,
} from "./coin.reducer";
import {
  statusRoleSystem
} from "./role.reducer";
import {
  isOpenMenuMobile
} from "./layout.reducer";

// Makansafe
import {
  FoodGenerateAI
} from "./makansafe.reducer";

let rootReducer = combineReducers({
  authUser,
  resetPassword,
  cases,
  portfolio,
  series,
  question,
  postQuestion,
  orders,
  pnorder,
  pnorderUpdate,
  pnorderCheck,
  theme,
  instructor,
  accountInstitution,
  actionsWidthCaseId,
  teachCase,
  getListCaseScript,
  loadCaseScript,
  saveCaseScript,
  getTemplateCaseScript,
  getQuestionBank,
  getQuestionBankOrg,
  shareMediaOrg,
  getListLearner,
  getMcqUsage,
  getDetailedMcqUsage,
  getSlideTemplates,
  getListSlideDesks,
  loadSlideDesk,
  createEngage,
  getAllEngages,
  getReferenceBook,
  shareReference,
  uploadFileAnalysis,
  createNewAnalysis,
  getListAnalysesAvailabel,
  getAnalysesAnswerBatch,
  getAnalysesAnswerBatchStatistics,
  getReferenceMedia,
  advance,
  timeline,
  timepoint,
  createCase,
  deleteCase,
  caseCompleted,
  checkAutoNextTimepoint,
  checkStatusNextTimePoint,
  dataFeedbacks,
  caseLearnersList,
  loadQuestion,
  valueChatSubmit,
  loadLabs,
  loadStudies,
  extractStudies,
  dataStudiesForElement,
  loadMedications,
  loadCheckpoints,
  itemsByCheckPoints,
  loadActionsTarget,
  loadAttributesForEffects,
  loadPnOrders,
  loadTemplates,
  allTemplate,
  loadCandidatesForLearner,
  loadExamFindingById,
  examMobileReducer,
  loadAllQuestion,
  loadChat,
  extractVitalSigns,
  extractLabs,
  dataPromptTemplate,
  register,
  verifyRegister,
  loadInstitutionAllCases,
  seriesLoadById,
  listCohort,
  dataLearnersByCohortId,
  loadPeriods,
  managers,
  loadCandidatesForManagers,
  loadFeedbackTemplate,
  ListAvailableFeedbackTemplates,
  typeChatforChatFeedback,
  chatFeedbackReducer,
  ERMModalMobileReducer,
  countChatVoice,
  submitTicket,
  loadTicket,
  listLearnersReport,
  getPerformanceFeedbacks,
  getQuestionRecord,
  dataDomainRecord,
  loadAllLaunchPlans,
  loadPlanDetails,
  featureList,
  loadTimeZoneInstitution,
  loadAccountTimeZone,
  loadTechHome,
  listTemplateCollectionById,
  dataOnboaringReport,
  getAssistantByid,
  getAgreements,
  updateQuestionGradingFields,
  loadStatisticsQuestionReport,
  listChatReport,
  listTopic,
  getDetailAgreements,
  billingReport,
  authCodeList,
  createAuthcode,
  LoadStripeCreditCard,
  dataAutomaticReNew,
  DataPaymentMethod,
  dataVitalSignsByCaseId,
  dataExamFindingsByCaseId,
  dataScrum,
  dataScrumById,
  dataSchema,
  dataQuestionForSchema,
  dataSearchSchema,
  dataTestQuestion,
  allQuestionForelement,
  chatRecordForQuestion,
  dataLoadRecordInstuctor,
  dataLoadRecordPatient,
  isChatPending,
  listAccountSettings,
  getAccountInfo,
  examFindingsByAtributeId,
  dataIntakeOutput,
  listLabsForElement,
  listStandardChatTopics,
  listMedicationsForElement,
  listActionsForElement,
  loadCaseByIdForElement,
  loadChatTopics,
  listAnswerbyQuestionId,
  listQuestionFormTemplates,
  getAllStandardChatTopics,
  LoadProblems,
  getAssociatedSchema,
  checkMissingTask,
  ChatRecordFreeText,
  getTagsForValidation,
  caseTaskCurrent,
  InterfaceTemplateData,
  statusTaskActiveTabMedicalRecord,
  VirtualPreceptorStatus,
  listInterfaceTemplates,
  listStandardInterfaceElements,
  listEngage,
  statusTaskActive,
  questionViewActive,
  getCoin,
  getCoinMcq,
  getCoinCaseScript,
  getRAGDocumentByAccount,
  loadWizard,
  loadNextStepWizard,
  fluxImageGeneration,
  isOpenMenuMobile,
  statusRoleSystem,
  // Makansafe
  FoodGenerateAI,
  routing: routerReducer,
});

export {
  rootReducer
};