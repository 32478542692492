import React from "react";
import { ButtonCloseERM } from "../ErmView";
import { useSelector } from "react-redux";

function PatientMain(props) {
    //props
    const { heading, iconPanel, children, stateType } = props && props;
    
    // Fetch necessary data from Redux store
    const statusTabActiveMedicalRecord = useSelector((state) => state.statusTaskActiveTabMedicalRecord?.activeTab || null);
    const questionData = useSelector((state) => state.question || {});
    const isQuestionNull = questionData?.data?.Question === null;

    // Convert heading to HTML
    const headingHTML = { __html: heading };

    // Nếu question null và click vào order như ở view chatWrapup thì sử dụng z-index 18000
    // Hoặc người dùng click ở view chat
    const zIndexStyle = {
        zIndex: (isQuestionNull && statusTabActiveMedicalRecord === 'Chat_Records') || stateType?.PatientErmFixed ? 18000 : 14000
    };

    return (
        <div
            className={`medRedcord-container ${(isQuestionNull && statusTabActiveMedicalRecord) || stateType?.PatientErmFixed ? "is-full-screen" : ""}`}
            style={zIndexStyle}
        >
            <div className="medRedcord-tabs">
                <div className="medRedcord-tabs__heading">
                    <span className={`medRedcord-tabs__heading--icon ${iconPanel} text-white`}></span>
                    <h3 className="text-white" dangerouslySetInnerHTML={headingHTML}></h3>
                    <ButtonCloseERM 
                        {...props}
                    />
                </div>
                <div className="medRedcord-tabs__body">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default PatientMain;